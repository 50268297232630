import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { createReturnsRows } from "./CreateReturnsTable";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { useLoaderDispatch } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { largeButton } from "../../utilities/style";
import useApiService from "../../utilities/useApiService";
import AreYouSure from "../../molecules/AreYouSure/AreYouSure";
import { Button, Input } from "@material-ui/core";
import { Box, Pagination } from "@mui/material";
import { Container, ButtonWrapper, useStyles } from "./ReturnPageStyles";
import {
  ReturnRequestServiceType,
  ReturnRequestStatus,
  UserRole,
} from "../../utilities/Enums";
import {
  isAdmin,
  isWholesalerUser,
  isRoleIncluded,
  isUserRoleNotEqual,
} from "../../utilities/checkUserRole";
import { useAuthState } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { usePharmacyEnabledStatus } from "../../context/PharmacyEnabledStatusContext";
import DeaAndStateLicenseStatus from "../../molecules/DeaAndStateLicenseStatus/DeaAndStateLicenseStatus";
import { checkExpiry } from "../../molecules/DeaAndStateLicenseStatus/expiryUtils";

export interface ReturnPageProps {
  pharmacy: number;
}

export const ReturnPage: React.FC<ReturnPageProps> = (
  props: ReturnPageProps
) => {
  const { user } = useAuthState();
  const userRole = user!.role;
  const history = useHistory();
  const showAllReturns =
    history.location.pathname.indexOf("/admin/all/pharmacies/returns") !== -1;
  const { deleteMethod, getMethod, postMethod, putMethod } = useApiService();
  const params: any = useParams();
  const loaderDispatch = useLoaderDispatch();
  const queryClient = useQueryClient();

  const [responseData, setResponseData] = useState<any>();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const pageSize = 15;
  const { pharmacyEnabledStatusMap, setPharmacyEnabledStatus } =
    usePharmacyEnabledStatus();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [toBeDeletedObject, setToBeDeletedObject] = useState({});

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [expiryType, setExpiryType] = useState<"DEA" | "State">("DEA");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const location = useLocation();
  const showSearchAndFilters =
    location.pathname === `/admin/all/pharmacies/returns`;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (value: string) => void
  ) => {
    setter(e.target.value);
  };

  const handleClear = () => {
    setSearchValue("");
    setSelectedStatus("");
    setSelectedServiceType("");
    setStartDate("");
    setEndDate("");
  };

  const getReturnRequestsApi = async (page: number, size: number) => {
    startLoading(loaderDispatch);
    let response;
    switch (userRole) {
      case UserRole.Admin:
      case UserRole.Warehouse:
      case UserRole.FieldProcessor:
        if (showAllReturns) {
          response = await getMethod(
            `/pharmacies/returnrequests?page=${page}&size=${size}&searchValue=${searchValue}&status=${selectedStatus}&serviceType=${selectedServiceType}&startDate=${startDate}&endDate=${endDate}`
          );
        } else {
          response = await getMethod(
            `/pharmacies/${params.pharmacyId}/returnrequests-admin?page=${page}&size=${size}&searchValue=${searchValue}&status=${selectedStatus}&serviceType=${selectedServiceType}&startDate=${startDate}&endDate=${endDate}`
          );
        }
        break;
      case UserRole.WholesalerUser:
        response = await getMethod(
          `/pharmacies/${params.pharmacyId}/returnrequests-wholesaleruser`
        );
        break;
      default:
        response = await getMethod(
          `/pharmacies/${params.pharmacyId}/returnrequests?page=${page}&size=${size}&searchValue=${searchValue}`
        );
    }
    stopLoading(loaderDispatch);
    response.data.content.forEach(({ returnRequest: { pharmacy } }: any) => {
      if (pharmacy && pharmacy?.id) {
        setPharmacyEnabledStatus(pharmacy.id, pharmacy.enabled);
      }
    });
    setResponseData(response.data);
    return response.data.content;
  };
  const { data: returnRequests } = useQuery(
    [
      "return-requests",
      currentPage,
      pageSize,
      searchValue,
      selectedStatus,
      selectedServiceType,
      startDate,
      endDate,
    ],
    () => getReturnRequestsApi(currentPage - 1, pageSize)
  );
  
  const getStatusOptionsApi = async () => {
    if (!isWholesalerUser(userRole)) {
      const response = await getMethod(
        `/pharmacies/returnrequests/return-request-status`
      );
      return response.data;
    }
  };
  const { data: statusOptions } = useQuery(
    "status-options",
    getStatusOptionsApi
  );

  const deleteReturnRequestApi = async ({ id, pharmacyId }: any) => {
    startLoading(loaderDispatch);
    if (isUserRoleNotEqual(user?.role, UserRole.WholesalerUser)) {
      if (showAllReturns) {
        await deleteMethod(`/pharmacies/${pharmacyId}/returnrequests/${id}`);
      } else {
        await deleteMethod(
          `/pharmacies/${params.pharmacyId}/returnrequests/${id}`
        );
      }
    }
    stopLoading(loaderDispatch);
    return id;
  };
  const { mutate: deleteReturnRequest } = useMutation(deleteReturnRequestApi, {
    onSuccess: (id) => {
      const newData = [...returnRequests];
      newData.forEach((item, i) => {
        if (item.returnRequest.id === id) {
          newData.splice(i, 1);
          return;
        }
      });
      queryClient.setQueryData("return-requests", newData);
    },
  });

  const updateStatusApi = async ({
    selectedOption,
    pharmacyId,
    requestId,
  }: any) => {
    let response;
    response = await putMethod(
      `/pharmacies/${pharmacyId}/returnrequests/${requestId}/status`,
      {
        returnRequestStatus: selectedOption,
      }
    );
    return { updatedRetReq: response.data, requestId };
  };
  const { mutate: updateStatus } = useMutation(updateStatusApi, {
    onSuccess: () => {
      queryClient.invalidateQueries("return-requests");
    },
  });

  const allExpirations = returnRequests?.map((item: any) => ({
    deaExp: item.returnRequest.pharmacy.deaExp,
    licenseStateExp: item.returnRequest.pharmacy.licenseStateExp,
  }));
  
  const handleClick = () => {
    const isUserRole = isRoleIncluded(
      [UserRole.User, UserRole.FieldProcessor],
      userRole
    );
    for (const expiration of allExpirations) {
      if (
        checkExpiry(
          expiration.deaExp,
          "DEA",
          isUserRole,
          setExpiryType,
          setIsPopupOpen
        )
      ) {
        return;
      }

      if (
        checkExpiry(
          expiration.licenseStateExp,
          "State",
          isUserRole,
          setExpiryType,
          setIsPopupOpen
        )
      ) {
        return;
      }
    }
    if (isRoleIncluded([UserRole.User], userRole)) {
      history.push(`/pharmacies/${params.pharmacyId}/returnrequest/create`);
    }  else {
      history.push(`/admin/pharmacies/${params.pharmacyId}/returnrequests/add`);
    }
  };

  const getHeaderTitles = () => {
    let headerTitles = ["Number", "Created At"];
    if (isAdmin(userRole) && showAllReturns) {
      headerTitles.push("Pharmacy", "User");
    }
    if (!isWholesalerUser(userRole)) {
      headerTitles.push("Items");
    }
    headerTitles.push("Status");
    if (!isWholesalerUser(userRole)) {
      headerTitles.push("UPS Shipments");
    }
    headerTitles.push("Reports");
    headerTitles.push("Service Type", "Preferred Date");
    if (
      (showAllReturns || pharmacyEnabledStatusMap[params.pharmacyId]) &&
      isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)
    ) {
      headerTitles.push("", "");
    } else {
      headerTitles.push("");
    }
    return headerTitles;
  };

  const renderCreateLabelButton = (item: any) => {
    const pharmacyId = params.pharmacyId
      ? params.pharmacyId
      : item.returnRequest.pharmacy?.id;

    const handleClick = () => {
      history.location.pathname.indexOf("admin") !== -1
        ? history.push(
            `/admin/pharmacies/${item.returnRequest.pharmacy.id}/returnrequests/${item.returnRequest.id}/shipments/create`
          )
        : history.push(
            `/pharmacies/${item.returnRequest.pharmacy.id}/returnrequests/${item.returnRequest.id}/shipments/create`
          );
    };

    return (
      <ButtonWrapper>
        <Button
          onClick={handleClick}
          disableElevation
          className="createLabelButton"
          disabled={!pharmacyEnabledStatusMap[pharmacyId] || isRoleIncluded([UserRole.Warehouse], userRole)}
        >
          Create Shipment
        </Button>
      </ButtonWrapper>
    );
  };

  const Filters = ({
    selectedStatus,
    setSelectedStatus,
    selectedServiceType,
    setSelectedServiceType,
    statusOptions,
  }: {
    selectedStatus: string;
    setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
    selectedServiceType: string;
    setSelectedServiceType: React.Dispatch<React.SetStateAction<string>>;
    statusOptions: string[];
  }) => {
    return (
      <div className={classes.filter}>
        <div>
          <label>Status:</label>
          <select
            value={selectedStatus ?? ""}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">All</option>
            {ReturnRequestStatus &&
              Object.values(ReturnRequestStatus).map((status, index) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Service Type:</label>
          <select
            value={selectedServiceType ?? ""}
            onChange={(e) => setSelectedServiceType(e.target.value)}
          >
            <option value="">All</option>
            {ReturnRequestServiceType &&
              Object.values(ReturnRequestServiceType).map((service, index) => (
                <option key={service} value={service}>
                  {service}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => handleChange(e, setStartDate)}
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => handleChange(e, setEndDate)}
          />
        </div>
        <button className="ClearButton" onClick={handleClear}>
          Clear
        </button>
      </div>
    );
  };

  const rows: Array<TableRowProps> = returnRequests
    ? createReturnsRows(
        returnRequests,
        history,
        userRole,
        showAllReturns,
        setOpenConfirmDelete,
        setToBeDeletedObject,
        updateStatus,
        statusOptions,
        renderCreateLabelButton,
        getMethod
      )
    : [];

  const renderCreateReturnRequestButton = () => {
    if (shouldCreateButtonBeVisible()) {
      return (
        <div>
        <DeaAndStateLicenseStatus type={expiryType} open={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <CallToActionButton
            height={"80px"}
            text="Start New Return"
            width={largeButton.width}
            onClick={handleClick}
            disabled={!pharmacyEnabledStatusMap[params.pharmacyId]}
          />
          </div>
        </div>
      );
    }
  };

  const shouldCreateButtonBeVisible = () => {
    return (
      isRoleIncluded(
        [
          UserRole.Admin,
          UserRole.User,
          UserRole.FieldProcessor,
          UserRole.Warehouse,
        ],
        userRole
      ) && !showAllReturns
    );
  };

  const renderAreYouSureDialog = () => {
    if (openConfirmDelete) {
      return (
        <AreYouSure
          open={openConfirmDelete}
          onClose={() => {
            setOpenConfirmDelete(false);
          }}
          toBeDeletedObject={toBeDeletedObject}
          deleteObject={deleteReturnRequest}
        />
      );
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const classes = useStyles();

  return (
    <Container>
      {showSearchAndFilters && (
        <Box>
          <Input
            id="searchField"
            type="text"
            value={searchValue}
            onChange={(e) => {
              setCurrentPage(1);
              setSearchValue(e.target.value);
            }}
            placeholder="Search By User Account or By Pharmacy Name"
            className={classes.searchField}
          />
          <Filters
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedServiceType={selectedServiceType}
            setSelectedServiceType={setSelectedServiceType}
            statusOptions={statusOptions}
          />
        </Box>
      )}
      {renderCreateReturnRequestButton()}
      <TableOrganism header={createHeaderRow(getHeaderTitles())} rows={rows} />
      {renderAreYouSureDialog()}
      <Box className={classes.paginationContainer}>
        <Pagination
          count={responseData?.totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};
