import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Container,
  TitleWrapper,
  DialogButtonsWrapper,
} from "../adminGenerateCheck/AdminGeneratePageStyles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useForm } from "react-hook-form";
import { TextBox } from "../../atoms/Text Box/TextBox";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import FormMolecule from "../../molecules/Form/FormMolecule";
import UploadFileArea from "../../atoms/UploadFileArea/UploadFileArea";
import useApiService from "../../utilities/useApiService";
import { useParams } from "react-router-dom";
import { IWholesaler } from "../../network/models/Interfaces";
import { getReportForm } from "./FormData";
import { SelectOption } from "../../utilities/SelectOptions";

export interface CreateReportDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateReportDialog: React.FC<CreateReportDialogProps> = ({
  open,
  onClose,
}) => {
  const { getMethod, postMethod } = useApiService();
  const queryClient = useQueryClient();
  const { control, handleSubmit, setValue, register, watch, formState: {errors} } = useForm();
  const params: any = useParams();
  const { pharmacyId, requestId } = params;

  const [file, setFile] = useState<any>();
  const [wholesalerSelectOptions, setWholesalerSelectOptions] = useState<
    Array<SelectOption>
  >([]);
  const typeValue = watch("type");
  const updatedWholesalerSelectOptions =
    typeValue === "Returns status" || typeValue === "Returns Reports"
      ? wholesalerSelectOptions
      : [];

  const registerFormFields = () => {
    register("type", { required: true });
    register("description", { required: true });
    register("wholesaler", { required: false });
    register("file", { required: true });
  };

  registerFormFields();

  const getWholesalersForPharmacyApi = async () => {
    const response = await getMethod(`/pharmacies/${pharmacyId}/wholesalers`);
    return response.data;
  };
  const { data } = useQuery(
    "pharmacy-wholesalers",
    getWholesalersForPharmacyApi,
    {
      onSuccess: (data: Array<IWholesaler>) => {
        setWholesalerSelectOptions(formulateSelectWholesalerOptions(data));
      },
    }
  );

  const formulateSelectWholesalerOptions = (
    wholesalers: Array<IWholesaler>
  ) => {
    if (Array.isArray(wholesalers)) {
      return wholesalers.map((wholesaler: IWholesaler) => ({
        value: wholesaler.id.toString(),
        label: wholesaler.name,
      }));
    } else {
      return [];
    }
  };

  const createReportApi = async (data: FormData) => {
    const response = await postMethod(`/admin/pharmacies/${pharmacyId}/returnrequests/${requestId}/reports-types`, data);
    return response.data
  }
  const { mutate: createReport } = useMutation(createReportApi, {
    onSuccess: () => {
      queryClient.invalidateQueries("reports");
      onClose();
    },
  });

  const handleFileDrop = (files: any) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);
    setValue("file", uploadedFile);
  };

  const handleConfirmClick = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("type", data.type);
    formData.append("description", data.description);
    if (typeValue === "Returns status" || typeValue === "Returns Reports") {
      formData.append("wholesalerId", data.wholesaler);
    } else {
      formData.append("wholesalerId", "null");
    }
    formData.append("file", data.file);
    createReport(formData);
  });

  return (
    <Container>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TitleWrapper>
          <TextBox
            bodyFontSize={18}
            headerFontSize={24}
            image={""}
            paragraphs={[""]}
            title={"New Report"}
            titleColor={"#A61C14"}
          />
        </TitleWrapper>
        <DialogContent>
          <form>
            <FormMolecule
              control={control as any}
              list={getReportForm(updatedWholesalerSelectOptions).filter(
                (item) =>
                  "options" in item[0] ? item[0].options.length > 0 : true
              )}
            />
            <UploadFileArea
              control={control as any}
              controllingName="file"
              onDrop={handleFileDrop}
              rules={{ required: "This field is required" }}
              file={file}
              error={errors.file ? true : false}
              errorMessage={errors.file?.message as string}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <DialogButtonsWrapper>
            <BigRedButton
              text="CANCEL"
              version={ButtonVersion.SMALL}
              onClick={onClose}
            />
            <Button className="approveButton" onClick={handleConfirmClick}>
              Confirm
            </Button>
          </DialogButtonsWrapper>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CreateReportDialog;
