import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import {
  DialogContainer,
  CloseButton,
  DialogTitleStyled,
  ContactDetails,
} from "./DeaAndStateLicenseStatusStyle";

interface PopupProps {
  open: boolean;
  onClose: () => void;
  type: "DEA" | "State";
}

const DeaAndStateLicenseStatus: React.FC<PopupProps> = ({
  open,
  onClose,
  type,
}) => {
  const title = type === "DEA" ? "DEA License Status" : "State License Status";
  const description =
    type === "DEA"
      ? "This feature has been disabled. Your DEA license information is not up to date."
      : "This feature has been disabled. Your State license information is not up to date.";

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContainer>
        <CloseButton onClick={onClose} aria-label="Close">
          &times;
        </CloseButton>
        <DialogTitleStyled>{title}</DialogTitleStyled>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <strong>{description}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <main>Please contact RxMax Returns at:</main>
          </Typography>
          <ContactDetails>
            <Typography variant="body1">
              <strong>Phone:</strong> (201) 252-8841
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> info@rxmaxreturns.com
            </Typography>
          </ContactDetails>
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
};

export default DeaAndStateLicenseStatus;
