import { UnitedStatesOptions, CompanyTypeOptions } from "../../utilities/SelectOptions";
import { SelectOption } from './../../utilities/SelectOptions';
import { isAccountNumber, isEmail, isPhoneNumber } from "../pharmacyProfile/formValidators";

export const step1 = (disableAccountNumber: boolean) => [
  [
    {
      controllingName: "pharmacy.dea",
      placeHolder: "DEA #",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.deaExp",
      placeHolder: "DEA # Expiry Date",
      type:"date",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacy.licenseState",
      placeHolder: "License state #",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.licenseStateExp",
      placeHolder: "License state Expiry Date",
      type:"date",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacy.npi",
      placeHolder: "NPI",
    },
    {
      controllingName: "pharmacy.doingBusinessAs",
      placeHolder: "Doing Business As",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.licenseStateCode",
      placeHolder: "License State Code",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacy.legalBusinessName",
      placeHolder: "Legal Business Name",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.accountNumber",
      placeHolder: "Account Number",
      rules: disableAccountNumber
        ? { validate: isAccountNumber }
        : { required: "this field is required", validate: isAccountNumber },
    },
  ],
  [
    {
      controllingName: "pharmacy.companyType",
      placeHolder: "Company Type",
      isSelect: true,
      rules: {
        required: "this field is required",
      },
      options: CompanyTypeOptions,
    },
  ],
];

export const contactInfo1 = [
  [
    {
      controllingName: "pharmacyContactInfo.firstName",
      placeHolder: "First Name",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacyContactInfo.lastName",
      placeHolder: "Last Name",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacyContactInfo.title",
      placeHolder: "Title",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacyContactInfo.email",
      placeHolder: "Email",
      rules: {
        required: "this field is required",
        validate: isEmail,
      },
    },
  ],
  [
    {
      controllingName: "pharmacyContactInfo.phone",
      placeHolder: "Phone",
      rules: {
        required: "this field is required",
        validate: isPhoneNumber,
      },
    },
    {
      controllingName: "pharmacyContactInfo.fax",
      placeHolder: "Fax",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacyContactInfo.additionalContact1",
      placeHolder: "Additional Contact 1"
    },
    {
      controllingName: "pharmacyContactInfo.additionalContact2",
      placeHolder: "Additional Contact 2"
    },
  ],
];

export const contactInfo2 = [
  [
    {
      controllingName: "pharmacyCompanyAddressInfo.address1",
      placeHolder: "Address 1",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacyCompanyAddressInfo.city",
      placeHolder: "City",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacyCompanyAddressInfo.state",
      placeHolder: "State",
      isSelect: true,
      rules: {
        required: "this field is required",
      },
      options: UnitedStatesOptions
    },
    {
      controllingName: "pharmacyCompanyAddressInfo.zip",
      placeHolder: "ZIP",
      rules: {
        required: "this field is required",
      },
    },
  ],
];

export const getWholesalerForm = (
  index: number,
  wholesalerSelectOptions: Array<SelectOption>
  // accountNumber: string
) => {
  return [
    [
      {
        controllingName: `wholeSalerInfo.id.${index}`,
        placeHolder: "Choose wholesaler",
        isSelect: true,
        rules: {
          required: "this field is required",
        },
        options: wholesalerSelectOptions,
      }
      //TODO: Add account number
      // {
      //   controllingName: `wholeSalerInfo.accountNumber.${index}`,
      //   placeHolder: accountNumber, //TODO: Switch to actual account number of wholesaler
      //   disabled: true
      // },
    ],
    [
      {
        controllingName: `wholeSalerInfo.address.${index}`,
        placeHolder: "Address",
        rules: {
          required: "this field is required",
        },
      },
      {
        controllingName: `wholeSalerInfo.city.${index}`,
        placeHolder: "City",
        rules: {
          required: "this field is required",
        },
      }
    ],
    [
      {
        controllingName: `wholeSalerInfo.state.${index}`,
        placeHolder: "State",
        isSelect: true,
        rules: {
          required: "this field is required",
        },
        options: UnitedStatesOptions
      },
      {
        controllingName: `wholeSalerInfo.zip.${index}`,
        placeHolder: "ZIP",
        rules: {
          required: "this field is required",
        },
      }
    ]
  ]
}

export const contactInfo3 = [
  [
    {
      controllingName: "pharmacyMailingAddressInfo.address1",
      placeHolder: "Address 1",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacyMailingAddressInfo.city",
      placeHolder: "City",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacyMailingAddressInfo.state",
      placeHolder: "State",
      isSelect: true,
      rules: {
        required: "this field is required",
      },
      options: UnitedStatesOptions
    },
    {
      controllingName: "pharmacyMailingAddressInfo.zip",
      placeHolder: "ZIP",
      rules: {
        required: "this field is required",
      },
    },
  ],
];

export const step4 = [
  [
    {
      controllingName: "promotionCode",
      placeHolder: "Referred By or Promotion Code",
    },
  ],
];

export const directDeposit = [
  [
    {
      controllingName: "pharmacy.directDepositInfo.aceNumber",
      placeHolder: "Account Number",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.directDepositInfo.routingNumber",
      placeHolder: "Routing Number",
      rules: {
        required: "this field is required",
      },
    },
  ],
  [
    {
      controllingName: "pharmacy.directDepositInfo.bankName",
      placeHolder: "Bank Name",
      rules: {
        required: "this field is required",
      },
    },
    {
      controllingName: "pharmacy.directDepositInfo.name",
      placeHolder: "Name",
      rules: {
        required: "this field is required",
      },
    },
  ],
];