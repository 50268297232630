import styled from "styled-components";

export const DialogTitleStyled = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #211d57;
`;

export const DialogContainer = styled.div`
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  position: relative;
  main {
    color: #530e0a;
  }
`;

export const ContactDetails = styled.div`
  margin-top: 8px;

  p {
    margin: 4px 0;
    font-size: 14px;
    color: #555;
  }

  strong {
    color: #333;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;
