import { sub } from "date-fns";

const calculateThresholdDate = (date: string | null): Date | null => {
  if (!date) return null;
  return sub(new Date(date), { weeks: 1 });
};

export const isExpiringSoon = (
  date: string | null,
  withinWeek: boolean
): boolean => {
  if (!date) return false;

  const targetDate = new Date(date);
  const now = new Date();
  const thresholdDate = calculateThresholdDate(date);

  if (withinWeek) {
    return (now >= thresholdDate! && now <= targetDate) || now > targetDate;
  }

  return now > targetDate;
};

export const isAnyExpiringSoon = (
    deaExp: string | null,
    licenseStateExp: string | null,
    withinWeek: boolean
  ): boolean => {
    return isExpiringSoon(deaExp, withinWeek) && isExpiringSoon(licenseStateExp, withinWeek);
  };

export const checkExpiry = (
  date: string | null,
  type: "DEA" | "State",
  withinWeek: boolean,
  setExpiryType: React.Dispatch<React.SetStateAction<"DEA" | "State">>,
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
): boolean => {
  const isExpired = isExpiringSoon(date, withinWeek);

  if (isExpired) {
    setExpiryType(type);
    setIsPopupOpen(true);
  }

  return isExpired;
};

export const hasAnyExpiring = (
    expirations: Array<{ deaExp: string | null; licenseStateExp: string | null }>,
    isUserRole: boolean
  ): boolean => {
    return expirations.some((expiration) =>
      checkExpiry(expiration.deaExp, "DEA", isUserRole, () => {}, () => {}) ||
      checkExpiry(expiration.licenseStateExp, "State", isUserRole, () => {}, () => {})
    );
  };

  export const isAnyExpiringSoonInEntry = (
    entry: {
      returnRequest: {
        pharmacy: { deaExp: string | null; licenseStateExp: string | null };
      };
    },
    withinWeek: boolean
  ): boolean => {
    const expirations = [
      entry.returnRequest.pharmacy.deaExp,
      entry.returnRequest.pharmacy.licenseStateExp,
    ];
  
    return expirations.some((date) => isExpiringSoon(date, withinWeek));
  };
