import { default as PharmacyInfoIcon } from "../../assets/pharmacyInfoIcon.svg";
import { default as ContactInfoIcon } from "../../assets/contactInfoIcon.svg";
import { default as WholesalerInfoIcon } from "../../assets/wholesaleInfoIcon.svg";
import { ICreatePharmacyFullInfo } from "../../network/models/PharmacyInterfaces";
import { UnitedStatesOptions, CompanyTypeOptions } from "../../utilities/SelectOptions";
import { FieldType } from "../../atoms/FormField/FormFieldAtomTypes";
import { IWholesaler } from "../../network/models/Interfaces";
import { IRearrangedPharmacyWholesalers } from "./PharmacyProfilePage";
import { SelectOption } from './../../utilities/SelectOptions';
import { isAccountNumber, isEmail, isPhoneNumber } from "./formValidators";

export const getSections = (response: ICreatePharmacyFullInfo) => {
  let reply: Array<any>;
  reply =
    [
      {
        sections: [
          {
            inputFields: [
              {
                controlingName: "dea",
                label: "DEA #",
                value: response.pharmacy?.dea,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                },
              },
              {
                controlingName: "deaExp",
                label: "DEA # Expiry Date",
                value: response.pharmacy?.deaExp,
                type:"date",
                disabled: true,
                rules: {
                  required: "This Field is Required"
                },
              },
              {
                controlingName: "licenseState",
                label: "License state",
                value: response.pharmacy.licenseState,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "licenseStateExp",
                label: "License state Expiry Date",
                value: response.pharmacy.licenseStateExp,
                type:"date",
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "npi",
                label: "NPI",
                value: response.pharmacy.npi,
                disabled: true,
              },
              {
                controlingName: "licenseStateCode",
                label: "License State Code",
                value: response.pharmacy.licenseStateCode,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "doingBusinessAs",
                label: "Doing Business As",
                value: response.pharmacy.doingBusinessAs,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "legalBusinessName",
                label: "Legal Business Name",
                value: response.pharmacy.legalBusinessName,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "accountNumber",
                label: "Account Number",
                value: response.pharmacy.accountNumber,
                rules: {
                  validate: isAccountNumber
                }
              },
              {
                discriminator: FieldType.Select,
                controllingName: "companyType",
                defaultValue: response.pharmacy.companyType.includes("Independent Pharmacy") ? "Independent Pharmacy" : "Long-term Care",
                options: CompanyTypeOptions,
                label: "Company Type"
              },
            ],
            subtitle: "",
            formTitle: "pharmacy",
          },
        ],
        title: "Business Info",
        icon: PharmacyInfoIcon,
        width: 300,
      },
      {
        sections: [
          {
            inputFields: [
              {
                controlingName: "firstName",
                label: "First Name",
                value: response.pharmacyContactInfo.firstName,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "lastName",
                label: "Last Name",
                value: response.pharmacyContactInfo.lastName,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "title",
                label: "Title",
                value: response.pharmacyContactInfo.title,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "email",
                label: "Email",
                value: response.pharmacyContactInfo.email,
                disabled: true,
                rules: {
                  required: "This Field is Required",
                  validate: isEmail,
                }
              },
              {
                controlingName: "phone",
                label: "Phone",
                value: response.pharmacyContactInfo.phone,
                disabled: true,
                rules: {
                  required: "This Field is Required",
                  validate: isPhoneNumber,
                }
              },
              {
                controlingName: "fax",
                label: "Fax",
                value: response.pharmacyContactInfo.fax,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "additionalContact1",
                label: "Additional Contact 1",
                value: response.pharmacyContactInfo.additionalContact1,
                disabled: true
              },
              {
                controlingName: "additionalContact2",
                label: "Additional Contact 2",
                value: response.pharmacyContactInfo.additionalContact2,
                disabled: true
              },
            ],
            subtitle: "Basic",
            formTitle: "pharmacyContactInfo",
          },
          {
            inputFields: [
              {
                controlingName: "address1",
                label: "Address 1",
                value: response.pharmacyCompanyAddressInfo.address1,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "city",
                label: "City",
                value: response.pharmacyCompanyAddressInfo.city,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                discriminator: FieldType.Select,
                controllingName: "state",
                defaultValue: response.pharmacyCompanyAddressInfo.state,
                options: UnitedStatesOptions,
                label: "State"
              },
              {
                controlingName: "zip",
                label: "ZIP",
                value: response.pharmacyCompanyAddressInfo.zip,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              }
            ],
            subtitle: "Address",
            formTitle: "pharmacyCompanyAddressInfo",
          },
          {
            inputFields: [
              {
                controlingName: "address1",
                label: "Address 1",
                value: response.pharmacyMailingAddressInfo.address1,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                controlingName: "city",
                label: "City",
                value: response.pharmacyMailingAddressInfo.city,
                disabled: false,
                rules: {
                  required: "This Field is Required"
                }
              },
              {
                discriminator: FieldType.Select,
                controllingName: "state",
                defaultValue: response.pharmacyMailingAddressInfo.state,
                options: UnitedStatesOptions,
                label: "State"
              },
              {
                controlingName: "zip",
                label: "ZIP",
                value: response.pharmacyMailingAddressInfo.zip,
                disabled: true,
                rules: {
                  required: "This Field is Required"
                }
              },
            ],
            subtitle: "Mailing Address",
            formTitle: "pharmacyMailingAddressInfo",
          },
        ],
        title: "Contact Info",
        icon: ContactInfoIcon,
        width: 300,
      },
      {
        sections: [
          {
            inputFields: [
              ],
          },
        ],
        title: response.pharmacy.wholesalersLinks?.length > 1 ? "Wholesalers" : "Wholesaler",
        icon: WholesalerInfoIcon,
        width: 300,
      },
      
    ]
  return reply;
};

export const getWholesalersForm = (
  index: number,
  rearrangedWholesalers: IRearrangedPharmacyWholesalers,
  wholesalerSelectOptions: Array<SelectOption>,
  wholesalerList: Array<IWholesaler>
) => {
  const choseWholesalers = rearrangedWholesalers.wholesalerIds;
  wholesalerSelectOptions = wholesalerSelectOptions.filter((option) => {
      return !choseWholesalers.includes(Number(option.value));
  });

  if (rearrangedWholesalers.wholesalerIds.length) {
    return [
      [
        {
          controllingName: `wholeSalerInfo.id.${index}`,
          placeHolder: wholesalerList.find((wholesaler: IWholesaler) => wholesaler.id === choseWholesalers[index])?.name || "Wholesaler",
          isSelect: true,
          disabled: !!wholesalerList.find((wholesaler: IWholesaler) => wholesaler.id === choseWholesalers[index])?.name,
          options: wholesalerSelectOptions,
          rules: { 
            required: {
              value: (wholesalerList.find((wholesaler: IWholesaler) => wholesaler.id === choseWholesalers[index])?.name || "Wholesaler") === "Wholesaler",
              message: "This Field is Required"
            }
          }
        },
      ],
      [
        {
          controllingName: `wholeSalerInfo.address.${index}`,
          placeHolder: rearrangedWholesalers.addresses[index] || "Address",
          disabled: false,
          rules: { 
            required: {
              value: (rearrangedWholesalers.addresses[index] || "Address") === "Address",
              message: "This Field is Required"
            }
          }
        },
        {
          controllingName: `wholeSalerInfo.city.${index}`,
          placeHolder: rearrangedWholesalers.cities[index] || "City",
          disabled: false,
          rules: { 
            required: {
              value: (rearrangedWholesalers.cities[index] || "City") === "City",
              message: "This Field is Required"
            }
          }
        },
      ],
      [
        {
          controllingName: `wholeSalerInfo.state.${index}`,
          placeHolder: rearrangedWholesalers.states[index] || "State",
          isSelect: true,
          disabled: false,
          options: UnitedStatesOptions,
          rules: { 
            required: {
              value: (rearrangedWholesalers.states[index] || "State") === "State",
              message: "This Field is Required"
            }
          }
        },
        {
          controllingName: `wholeSalerInfo.zip.${index}`,
          placeHolder: rearrangedWholesalers.zipCodes[index] || "Zip Code",
          disabled: false,
          rules: { 
            required: {
              value: (rearrangedWholesalers.zipCodes[index] || "Zip Code") === "Zip Code",
              message: "This Field is Required"
            }
          }
        },
      ]
    ]
  }
}

export const getAcc = (
  rearrangedWholesalers: IRearrangedPharmacyWholesalers,
  wholesalerSelectOptions: Array<SelectOption>,
  wholesalerList: Array<IWholesaler>
) => {
  var len = rearrangedWholesalers.wholesalerIds.length;
  var Acc:any=[]
  for (let i = 0; i < len; i++) {
    Acc.push(getWholesalersForm(i, rearrangedWholesalers, wholesalerSelectOptions, wholesalerList))
  }
  return Acc
}