import React, { useMemo } from "react";
import TextFieldM, { TextFieldVersion } from "../../atoms/InputText/TextFieldM";
import { Control, Controller } from "react-hook-form";
import Select from "../../atoms/InputSelect/Select";
import { Container, Wrapper } from "./FormStyles";
import Label from "../../atoms/Label/Label";
import FormControl from "@material-ui/core/FormControl";
import CheckBoxAtom from "../../atoms/InputCheckbox/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

interface rowProps {
  controllingName: string;
  placeHolder: string;
  // value?:string;
  // defaultValue?:string;
  type?: string;
  disabled?: boolean;
  minDate?: string;
  rules?: any;
  isSelect?: boolean;
  helperText?: string;
  options?:
    | Array<{
        value: string;
        label: string;
      }>
    | undefined;
  isCheck?: boolean;
  checkOptions?: {
    value: string;
    label: string;
  };
  isRadioGroup?: boolean;
  radioButtons?: Array<{ value: string, label: string }>;
  radioGroupTitle?: string;
  radioDefaultValue?: string;
  radioValue?: string;
  onChange?: (event: any) => void;
  onInputKeyDown?: (event: any, controlingName: string) => void;
  isMultiline?: boolean;
  maxRows?: number;
  hintText?: string;
  disableAccountNumber?: boolean;
}

export interface FormProps {
  list: Array<Array<rowProps>>;
  control: Control;
  label?: string;
  disabled?: boolean;
  disableAccountNumber?: boolean;
  onInputFieldKeyDown?: (
    e: React.KeyboardEvent<HTMLDivElement>,
    controlingName: string
  ) => void;
  ndcRef?: React.Ref<HTMLInputElement>;
}

const FormMolecule: React.FC<FormProps> = ({
  list = [],
  control,
  label = "",
  disabled = false,
  disableAccountNumber,
  onInputFieldKeyDown,
  ndcRef,
}) => {
  const renderForm = useMemo(() => {
    return list.map((row, rowIndex) => (
      <Container
        key={rowIndex}
        width={row.length === 1 ? "100%" : row.length === 2 ? "49%" : "33%"}
      >
        {row.map((field, fieldIndex) => {
          const isAccountNumberField = field.controllingName === "pharmacy.accountNumber";
          const isFieldDisabled = isAccountNumberField ? disableAccountNumber : field.disabled;

          return field.isSelect ? (
            <Select
              key={fieldIndex}
              control={control}
              controllingName={field.controllingName}
              placeHolder={field.placeHolder}
              options={field.options}
              rules={field.rules}
              disabled={isFieldDisabled}
            ></Select>
          ) : field.isCheck && field.checkOptions ? (
            <CheckBoxAtom
              key={fieldIndex}
              control={control}
              controllingName={field.controllingName}
              value={field.checkOptions.value}
              label={field.checkOptions.label}
              onChange={field.onChange}
            />
          ) : field.isRadioGroup && field.radioButtons ? (
            <FormControl key={fieldIndex} className="radio-wrapper">
              <fieldset className="radio-border">
                {field.radioGroupTitle && (
                  <legend className="legend">
                    <Typography
                      className="radioGroupTitle"
                      variant="caption"
                      color="textSecondary"
                    >
                      {field.radioGroupTitle}
                    </Typography>
                  </legend>
                )}
                <Controller
                  control={control}
                  name={field.controllingName}
                  defaultValue={field.radioDefaultValue}
                  rules={field.rules}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      className="custom-radio-group"
                    >
                      {field.radioButtons?.map((radioButton) => (
                        <FormControlLabel
                          key={radioButton.value}
                          value={radioButton.value}
                          control={<Radio className="custom-radio" />}
                          label={
                            <span className="custom-label">
                              {radioButton.label}
                            </span>
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </fieldset>
            </FormControl>
          ) : (
            <TextFieldM
              key={fieldIndex}
              version={TextFieldVersion.AUTO}
              control={control}
              controlingName={field.controllingName}
              placeHolder={field.placeHolder}
              type={field.type}
              rules={field.rules}
              disabled={isFieldDisabled}
              minDate={field.minDate}
              helperText={field.helperText}
              isMultiline={field.isMultiline}
              maxRows={field.maxRows}
              hintText={field.hintText}
              onKeyDown={onInputFieldKeyDown}
              inputRef={field.controllingName === "ndc" ? ndcRef : undefined}
              autoComplete={field.controllingName === "ndc" ? false: true}
            />
          );
        })}
      </Container>
    ));
  }, [list, control, disabled, disableAccountNumber, onInputFieldKeyDown]);

  return (
    <Wrapper>
      {label !== "" && <Label label={label} className="title" />}
      {renderForm}
    </Wrapper>
  );
};
export default FormMolecule;
