import { History } from "history";
import moment from "moment";
import download from "downloadjs";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
  createClickableCell,
  createSelectCell,
  createCellWithChildren,
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import { toastFail, toastInfo } from "../../utilities/toast";
import { UserRole, ReturnRequestStatus } from "../../utilities/Enums";
import {
  isAdmin,
  isWholesalerUser,
  isRoleIncluded,
  isRoleAndStatusAllowed
} from "../../utilities/checkUserRole";
import { isAnyExpiringSoon, isAnyExpiringSoonInEntry } from "../../molecules/DeaAndStateLicenseStatus/expiryUtils";

//TODO: Implement interfaces and types
export const createReturnsRows = (
  data: any,
  history: History,
  userRole: string,
  showAllReturns: boolean,
  setOpenConfirmDelete: any,
  setToBeDeletedObject: any,
  updateStatus: any,
  statusOptions: Array<string>,
  renderChildren: any,
  getMethod: (url: any, payload?: any) => Promise<any>
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry: any) => {
    const currentStatus = entry.returnRequest?.pharmacy?.enabled || false;
    const row: TableRowProps = createRow(
      createNumberCell(entry),
      createCreatedAtCell(entry),
      createPharmacyCell(entry, userRole, showAllReturns),
      createUserCell(entry, userRole, showAllReturns),
      createItemsCell(entry, userRole, history),
      createStatusCell(
        entry,
        userRole,
        statusOptions,
        updateStatus,
        currentStatus
      ),
      createUpsShipmentsCell(entry, userRole, history, renderChildren,updateStatus),
      createReportsCell(entry, history),
      createServiceTypeCell(entry),
      createPreferredDateCell(entry),
      createGenerateExcelCell(entry, userRole, getMethod),
      createEditCell(entry, userRole, history, currentStatus)
    );
    rows.push(row);
  });
  return rows;
};
const createNumberCell = (entry: any) => {
  return createInfoCell(entry.returnRequest.id);
};

const createCreatedAtCell = (entry: any) => {
  return createInfoCell(
    moment(entry.returnRequest.createdAt).format("YYYY-MM-DD")
  );
};

const createPharmacyCell = (
  entry: any,
  userRole: string,
  showAllReturns: boolean
) => {
  if (isAdmin(userRole) && showAllReturns) {
    return createInfoCell(entry.returnRequest.pharmacy?.doingBusinessAs!);
  }
};

const createUserCell = (
  entry: any,
  userRole: string,
  showAllReturns: boolean
) => {
  if (isAdmin(userRole) && showAllReturns) {
    return createInfoCell(
      capitalize(entry.returnRequest.pharmacy?.user?.username!)
    );
  }
};

const capitalize: any = (str: string): string => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

const createItemsCell = (entry: any, userRole: string, history: History) => {
  if (!isRoleIncluded([UserRole.WholesalerUser], userRole)) {
    return createClickableCell(() => {
      if (isRoleIncluded([UserRole.User], userRole)) {
        history.push(
          `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/itemsPage`
        );
      } else {
        history.push(
          `/admin/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/items`
        );
      }
    }, entry.numberOfItems);
  }
};

const createStatusCell = (
  entry: any,
  userRole: string,
  statusOptions: Array<string>,
  updateStatus: any,
  pharmacyEnabledStatus: boolean
) => {
  if (
    (isAuthorizedToUpdateStatus(userRole, entry) &&
    pharmacyEnabledStatus ) && !isAnyExpiringSoonInEntry(
      entry,
      isRoleIncluded([UserRole.User, UserRole.FieldProcessor], userRole)
    )
  ) {
    const statusOptionsBasedOnUser = getStatusOptionsForUser(
      statusOptions,
      userRole,
      entry.returnRequest.returnRequestStatus
    );
    return createSelectCell(
      statusOptionsBasedOnUser,
      entry.returnRequest.returnRequestStatus,
      (event: any) => {
        updateStatus({
          selectedOption: event.target.value,
          pharmacyId: entry.returnRequest.pharmacy.id,
          requestId: entry.returnRequest.id,
        });
      }
    );
  }
  return createInfoCell(entry.returnRequest.returnRequestStatus);
};

const isAuthorizedToUpdateStatus = (userRole: string, entry: any): boolean => {
  const currentStatus = entry.returnRequest.returnRequestStatus;

  if (isAdmin(userRole)) return true;

  if (isRoleIncluded([UserRole.Warehouse], userRole)) {
    return [
      ReturnRequestStatus.Preparing,
      ReturnRequestStatus.InTransit,
      ReturnRequestStatus.Received,
      ReturnRequestStatus.Processing,
      ReturnRequestStatus.Processed,
      ReturnRequestStatus.Shipped,
    ].includes(currentStatus);
  }

  if (isRoleIncluded([UserRole.User, UserRole.FieldProcessor], userRole)) {
    return [ReturnRequestStatus.Preparing].includes(currentStatus);
  }

  return false;
};

const isPaidStatus = (currentStatus: string): boolean => {
  return (
    currentStatus === ReturnRequestStatus.PaidByManufacturer ||
    currentStatus === ReturnRequestStatus.PaidDirectly ||
    currentStatus === ReturnRequestStatus.PaidThroughWholesaler
  );
};

const getStatusOptionsForUser = (
  statusOptions: string[],
  userRole: string,
  currentStatus: string
): { value: string; label: string }[] => {
  let maxTotalOptions = statusOptions.length;

  if (isRoleIncluded([UserRole.Warehouse], userRole)) {
    maxTotalOptions = 8;
  } else if (
    isRoleIncluded([UserRole.User, UserRole.FieldProcessor], userRole)
  ) {
    maxTotalOptions = 3;
  }

  if (isPaidStatus(currentStatus)) {
    statusOptions = [currentStatus, statusOptions[statusOptions.length - 1]];
    maxTotalOptions = statusOptions.length;
  }

  const currentStatusIndex = statusOptions.indexOf(currentStatus);
  return statusOptions
    .slice(currentStatusIndex, maxTotalOptions)
    .map((option) => ({
      value: option,
      label: option,
    }));
};

const createUpsShipmentsCell = (
  entry: any,
  userRole: string,
  history: History,
  renderChildren: any,
  updateStatus: any
) => {
  const currentStatus : ReturnRequestStatus = entry.returnRequest.returnRequestStatus;
  if (isAnyExpiringSoonInEntry(
    entry,
    isRoleIncluded([UserRole.User, UserRole.FieldProcessor], userRole)
  ) || (isCancelled(currentStatus))) {
    return " ";
  }
  if (!isWholesalerUser(userRole)) {
    if (noShipmentsYet(entry)) {
      return createCellWithChildren(renderChildren(entry));
    }
    if (entry.returnRequest.returnRequestStatus !== ReturnRequestStatus.InTransit) {
      updateStatus({
        selectedOption: ReturnRequestStatus.InTransit,
        pharmacyId: entry.returnRequest.pharmacy.id,
        requestId: entry.returnRequest.id,
      });
    }
    return createClickableCell(() => {
      history.push(
        `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/shipments`
      );
    }, entry.numberOfShipments);
  }
};

const isCancelled = (status: ReturnRequestStatus) : boolean => {
  return status === ReturnRequestStatus.Canceled;
}
const noShipmentsYet = (entry: any) => {
  return entry.numberOfShipments === 0;
};

const createReportsCell = (entry: any, history: History) => {
  return createClickableCell(() => {
    history.push(
      `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/reports`
    );
  }, entry.numberOfReports);
};

const createServiceTypeCell = (entry: any) => {
  return createInfoCell(entry.returnRequest.serviceType);
};

const createPreferredDateCell = (entry: any) => {
  const preferredDate = entry.returnRequest.preferredDate;
  if (!preferredDate) {
    return createInfoCell("");
  }
  const dateObj = new Date(preferredDate);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    timeZone: "UTC",
  });
  return createInfoCell(formattedDate);
};

const createGenerateExcelCell = (
  entry: any,
  userRole: string,
  getMethod: any
) => {
  if (isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)) {
    return createClickableCell(async () => {
      toastInfo(
        "Generating Excel Report for return request " + entry.returnRequest.id
      );
      let response = await getMethod(
        `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/excel`,
        {
          responseType: "blob",
        }
      );
      if (response.response && response.response.status >= 400) {
        toastFail(
          "Can not generate a report for a request with all 'Pending' items!"
        );
      } else {
        download(
          response.data,
          `${entry.returnRequest.pharmacy.doingBusinessAs}-${entry.returnRequest.id}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }
    }, "Generate Excel");
  }
};

const createEditCell = (
  entry: any,
  userRole: string,
  history: History,
  pharmacyEnabledStatus: boolean
) => {
  const currentStatus = entry.returnRequest.returnRequestStatus;
  if (pharmacyEnabledStatus) {
    if (
      isAnyExpiringSoon(
        entry.returnRequest.pharmacy.deaExp,
        entry.returnRequest.pharmacy.licenseStateExp,
        isRoleIncluded([UserRole.User, UserRole.FieldProcessor], userRole)
      )
    ) {
      return;
    }
    if (
      isAdmin(userRole) ||
      isRoleAndStatusAllowed(
        [
          UserRole.FieldProcessor,
          UserRole.User,
          UserRole.WholesalerUser,
        ],
        userRole,
        [ReturnRequestStatus.Preparing],
        currentStatus
      ) ||
      isRoleAndStatusAllowed(
        [UserRole.Warehouse],
        userRole,
        [ 
          ReturnRequestStatus.Preparing, 
          ReturnRequestStatus.InTransit,
          ReturnRequestStatus.Received,
          ReturnRequestStatus.Processing,
          ReturnRequestStatus.Processed,
          ReturnRequestStatus.Shipped
        ],
        currentStatus
      )
    ) {
      return createClickableCell(() => {
      if (isRoleIncluded([UserRole.User], userRole)) {
        history.push(
          `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/add`
        );
      } else {
        history.push(
          `/admin/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/add`
        );
      }
    }, "Edit");
    } else {
      return " ";
    }
  }
};
